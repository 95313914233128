'use client';

import { MDXComponentsClient } from '@/components/MDX/MDXComponentMap';
import { MDXRemote, MDXRemoteSerializeResult } from 'next-mdx-remote';
import { useEffect, useLayoutEffect, useState } from 'react';

const ClientMDXComponent = ({
	fileContent,
}: {
	fileContent: MDXRemoteSerializeResult;
}) => {
	const [component, setComponent] = useState<JSX.Element | null>(null);

	useEffect(() => {
		(async () => {
			setComponent(
				<MDXRemote
					{...fileContent}
					components={{
						...MDXComponentsClient,
					}}
				/>,
			);
		})();
	}, [fileContent]);

	useLayoutEffect(() => {
		if (component) {
			// Remove server-side rendered article
			const serverSideRenderedArticle =
				document.querySelector('#article-ssr');
			if (serverSideRenderedArticle) {
				serverSideRenderedArticle.remove();
			}
		}
	}, [component]);

	if (!component) return <></>;

	return component;
};

export default ClientMDXComponent;
