'use client';

import { Prose } from '@/components/ArticleAccessibilityMenu/ArticleAccessibilityTextSize';
import IconButton from '@/components/Button/IconButton';
import EmojiText from '@/components/EmojiText/EmojiText';
import { NavigationItem } from '@/components/Navigation/NavigationItem';
import Section from '@/components/Section/Section';
import { useChapterProgress } from '@/hooks/useChapterProgress';
import { mdiCheckDecagram, mdiCheckDecagramOutline } from '@mdi/js';
import { motion, useReducedMotion } from 'framer-motion';
import { useLayoutEffect, useState } from 'react';

const MarkCompletedButton = ({
	navigationItem,
}: {
	navigationItem: NavigationItem;
}) => {
	const reduceMotion = useReducedMotion();

	const {
		isPageCompleted,
		addPageToChapterProgress,
		removePageFromChapterProgress,
	} = useChapterProgress(
		navigationItem.parentPath.length > 1
			? navigationItem.parentPath.split('/')[0]
			: navigationItem.name,
		navigationItem,
	);

	const pagePath = [navigationItem.parentPath ?? '/', navigationItem.name]
		.filter((pathPart) => !!pathPart)
		.join('/');
	const isCompleted = isPageCompleted(pagePath);

	// Only show after first render, to prevent "html doesn't match server" error
	const [showElement, setShowElement] = useState<boolean>(false);
	useLayoutEffect(() => {
		setShowElement(true);
	}, []);

	if (!showElement) return <></>;

	return (
		<>
			<motion.footer
				className="sticky bottom-0 mx-auto w-full max-w-3xl pt-4"
				initial={{
					opacity: 0,
				}}
				animate={{
					opacity: 1,
					transition: {
						delay: isCompleted ? 0.1 : 0.5,
					},
				}}
			>
				<Section
					as="div"
					className={`flex items-center justify-center gap-4 bg-gradient-to-b px-2 pb-4 pt-20 max-sm:flex-col md:gap-8 md:px-4 md:pb-12 lg:px-8 lg:pb-24 ${
						isCompleted
							? 'bg-green-500/30 dark:bg-green-500/30'
							: 'bg-yellow-100 dark:bg-yellow-900'
					} transition-all duration-700`}
				>
					<p
						aria-hidden
						className="relative flex size-32 shrink-0 -rotate-3 select-none items-center justify-center text-7xl"
					>
						{reduceMotion ? (
							<EmojiText>{isCompleted ? '✅' : '📒'}</EmojiText>
						) : (
							<>
								<motion.span
									className="absolute"
									animate={{
										rotate: isCompleted ? 40 : -3,
										scale: isCompleted ? 0 : 1,
									}}
								>
									<EmojiText>📒</EmojiText>
								</motion.span>
								<motion.span
									className="absolute"
									animate={{
										rotate: !isCompleted ? 40 : -3,
										scale: !isCompleted ? 0 : 1,
									}}
								>
									<EmojiText>✅</EmojiText>
								</motion.span>
							</>
						)}
					</p>
					<div className="flex flex-col items-start gap-4 rounded-lg bg-white/90 p-4 shadow-md dark:bg-slate-800/90">
						<Prose>
							<p className="max-w-md">
								Markeer deze pagina als voltooid om te zien
								hoeveel je al hebt geleerd!
							</p>
						</Prose>
						<IconButton
							isLarge
							isTogglePressed={isCompleted}
							label={
								isCompleted
									? 'Voltooid'
									: 'Markeer als voltooid'
							}
							icon={
								isCompleted
									? mdiCheckDecagram
									: mdiCheckDecagramOutline
							}
							title={
								isCompleted
									? 'Gemarkeerd als voltooid'
									: 'Markeer pagina als voltooid'
							}
							colorVariant={isCompleted ? 'primary' : undefined}
							onFocus={() => {
								// Scroll the button into view on focus
								document.querySelector('main')?.scrollTo({
									top:
										document.querySelector('main')
											?.scrollHeight ?? 0,
									behavior: reduceMotion ? 'auto' : 'smooth',
								});
							}}
							onClick={() => {
								// Toggle the page's completion status
								if (isCompleted) {
									removePageFromChapterProgress(pagePath);
								} else {
									addPageToChapterProgress(pagePath);
								}
							}}
						/>
					</div>
				</Section>

				{/* <div className="mx-auto py-16 px-2 md:px-4 lg:px-8 max-w-lg text-center text-green-900">
					Deze website is een pilot vanuit NTR Digitaal om het
					bewustzijn rondom toegankelijkheid te vergroten.
					<br />
					Heb je vragen of feedback?
					<br />
					Stuur deze naar Tijmen van NTR Digitaal!
				</div> */}
			</motion.footer>
		</>
	);
};

export default MarkCompletedButton;
