'use client';

import { Prose } from '@/components/ArticleAccessibilityMenu/ArticleAccessibilityTextSize';
import ContrastTool from '@/components/ContrastTool/ContrastTool';
import H1 from '@/components/MDX/H1';
import { Suspense, useLayoutEffect, useState } from 'react';

const EmbeddedContrastTool = () => {
	const [showContent, setShowContent] = useState<boolean>(false);

	useLayoutEffect(() => {
		setShowContent(true);
	}, []);

	return (
		<>
			{showContent && (
				<Prose as="header" className="mb-8">
					<H1>Contrast meten</H1>
					<p>
						Behaal minimaal niveau <strong>AA</strong> en streef
						naar AAA. Met AAA kunnen de meeste mensen jouw teksten
						fijn lezen.
					</p>
				</Prose>
			)}
			<Suspense
				fallback={<p role="status">Contrast tool wordt geladen...</p>}
			>
				<div className="not-prose">
					<Suspense>
						<ContrastTool />
					</Suspense>
				</div>
			</Suspense>
		</>
	);
};

export default EmbeddedContrastTool;
