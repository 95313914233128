import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/src/app/(main)/[...doc]/ClientMDXComponent.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/(main)/[...doc]/EmbeddedContrastTool.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/(main)/[...doc]/EmbeddedGuidelinesList.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/(main)/[...doc]/MarkCompletedButton.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/AltDecisionTree/AltDecisionTree.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ArticleAccessibilityMenu/ArticleAccessibilityMenu.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ArticleAccessibilityMenu/ArticleAccessibilityTextSize.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Button/IconButton.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/EmojiText/EmojiText.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MDX/CheckList.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MDX/Iframe.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MDX/Image.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MDX/MultipleChoice.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MDX/Personas.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MDX/SingleTip.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/NewsOverview/NewsOverview.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Section/Section.module.sass");
import(/* webpackMode: "eager" */ "/app/src/components/ShareButton/ShareButton.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/SimulationTool/SimulationTool.tsx")