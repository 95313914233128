/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @next/next/no-img-element */
'use client';

import {
	BlurredVisionSimulationFilterSwitch,
	ColorBlindnessSimulationFilterDropdown,
} from '@/components/ArticleAccessibilityMenu/ArticleAccessibilityMenu';
import { Prose } from '@/components/ArticleAccessibilityMenu/ArticleAccessibilityTextSize';
import H1 from '@/components/MDX/H1';
import { mdiUpload } from '@mdi/js';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useLayoutEffect, useRef, useState } from 'react';
import IconButton from '../Button/IconButton';

const SimulationTool = () => {
	const pathname = usePathname() ?? '';

	const fileInputRef = useRef<HTMLInputElement>(null);
	const [selectedImage, setSelectedImage] = useState<string>('');

	const [showContent, setShowContent] = useState<boolean>(false);

	useLayoutEffect(() => {
		setShowContent(true);
	}, []);

	if (!showContent) {
		return null;
	}

	// Check if inside a sub page (xyz/simuleren) or the main page (simuleren)
	const isSubPage = pathname.split('/').length > 2;
	const mainPagePath = pathname.split('/').slice(0, 2).join('/');

	return (
		<>
			<Prose as="header" className="mb-8">
				<H1>Simuleren</H1>
				<p>
					Deze tool is slechts een simulatie voor{' '}
					<strong>bewustwording</strong>. Zorg dat je aan de{' '}
					<Link
						href={
							isSubPage ? `${mainPagePath}/contrast` : '/contrast'
						}
					>
						contrast
					</Link>{' '}
					richtlijnen voldoet. Zorg er ook voor dat graphics, zoals
					grafieken of kaarten,{' '}
					<Link href="/grafisch-ontwerper/kleur">
						onafhankelijk zijn van kleur
					</Link>
					. Je kan hier ook meer leren over{' '}
					<Link href="/wie/functievariatie/visueel">
						visuele functievariatie
					</Link>
					.
				</p>
			</Prose>

			<div className="not-prose">
				<form
					className="mb-4 flex flex-wrap gap-4"
					onSubmit={(event) => event.preventDefault()}
				>
					<label className="hidden" htmlFor="file">
						Upload een afbeelding
					</label>
					<input
						ref={fileInputRef}
						type="file"
						className="hidden"
						id="file"
						accept="image/*"
						onChange={(event) => {
							if (
								event.target.files &&
								event.target.files.length > 0
							) {
								const reader = new FileReader();
								reader.onload = (e) => {
									setSelectedImage(
										e.target?.result as string,
									);
								};
								reader.readAsDataURL(event.target.files[0]);
							}
						}}
					/>
					<IconButton
						icon={mdiUpload}
						label="Kies een afbeelding"
						title="Kies een afbeelding"
						isLarge={!selectedImage}
						onClick={() => {
							fileInputRef.current?.click();
						}}
					/>
				</form>

				{!!selectedImage && (
					<section
						aria-label="Simulatie"
						className="not-prose mb-4 rounded-md bg-slate-100 p-4 shadow-md dark:bg-slate-800"
					>
						<p className="mb-1 text-base">
							Klik bovenaan de pagina op &apos;Instellingen&apos;
							voor meer simulatie opties.
						</p>
						<div className="grid gap-4 md:grid-cols-2">
							<ColorBlindnessSimulationFilterDropdown />
							<BlurredVisionSimulationFilterSwitch />
						</div>
						<img
							src={selectedImage}
							alt="Geselecteerde afbeelding"
							className="w-full rounded-md shadow-md"
						/>
					</section>
				)}
			</div>
		</>
	);
};

export default SimulationTool;
