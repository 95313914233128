'use client';

import GuidelinesList from '@/app/(main)/richtlijnen/GuidelinesList';
import { Prose } from '@/components/ArticleAccessibilityMenu/ArticleAccessibilityTextSize';
import IconButton from '@/components/Button/IconButton';
import H1 from '@/components/MDX/H1';
import { GuidelineCategoriesSectionWithGuidelineCategoriesWithGuidelines } from '@/resources/guidelines';
import { Job } from '@/resources/jobs';
import { mdiOpenInNew } from '@mdi/js';
import { Suspense, useLayoutEffect, useState } from 'react';
import GuidelinesFilters from '../richtlijnen/GuidelinesFilters';

const EmbeddedGuidelinesList = ({
	guidelineCategoriesWithGuidelines,
	job,
}: {
	guidelineCategoriesWithGuidelines: GuidelineCategoriesSectionWithGuidelineCategoriesWithGuidelines[];
	job?: Job;
}) => {
	const [showContent, setShowContent] = useState<boolean>(false);

	useLayoutEffect(() => {
		setShowContent(true);
	}, []);

	return (
		<>
			{showContent && (
				<Prose className="mb-8">
					<H1>{job ? 'Mijn richtlijnen' : 'Richtlijnen'}</H1>
					{job ? (
						<p>
							Hier vind je <strong>een aantal richtlijnen</strong>{' '}
							die vanuit jouw vak belangrijk kunnen zijn.
							Uiteindelijk moet een productie aan alle richtlijnen
							voldoen. Bekijk alle belangrijke richtlijnen op de
							aparte pagina. Om niveau AA te behalen, moet niveau
							A ook behaald zijn.
						</p>
					) : (
						<p>
							Hier vind je alle richtlijnen die belangrijk zijn
							voor de toegankelijkheid van een productie. Bekijk
							de richtlijnen die voor jouw vak belangrijk zijn op
							de <strong>aparte pagina</strong>. Klik daarvoor op
							de knop hieronder. Om niveau AA te behalen, moet
							niveau A ook behaald zijn.
						</p>
					)}
					<IconButton
						icon={mdiOpenInNew}
						label="Alles bekijken en zoeken"
						title="Bekijk alle richtlijnen en filter, opent in nieuw tabblad"
						onClick={() => window.open('/richtlijnen', '_blank')}
					/>
				</Prose>
			)}
			<div className="not-prose grid gap-6 text-base">
				<hr className="mb-2" />
				<Suspense>
					<GuidelinesFilters
						isSearchEnabled={false}
						isJobsEnabled={false}
					/>
					<GuidelinesList
						guidelineCategorySections={
							guidelineCategoriesWithGuidelines
						}
						jobsFilter={job ? [job.id] : []}
					/>
				</Suspense>
			</div>
		</>
	);
};

export default EmbeddedGuidelinesList;
