'use client';

import Filter from '@/components/Filter/Filter';
import { Jobs } from '@/resources/jobs';
import { Tags } from '@/resources/tags';
import { mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

const GuidelinesFilters = ({
	isSearchEnabled = true,
	isJobsEnabled = true,
	isWCAGLevelsEnabled = true,
	isTagsEnabled = true,
}: {
	isSearchEnabled?: boolean;
	isJobsEnabled?: boolean;
	isWCAGLevelsEnabled?: boolean;
	isTagsEnabled?: boolean;
}) => {
	// Get search and jobs filter from url
	const searchParams = useSearchParams();

	const [searchFilter, setSearchFilter] = useState<string>(
		searchParams?.get('q') ?? '',
	);

	useEffect(() => {
		// Update url with search filter
		const url = new URL(window.location.href);
		if (searchFilter.length > 0) {
			url.searchParams.set('q', searchFilter);
		} else {
			url.searchParams.delete('q');
		}

		window.history.replaceState({}, '', url.toString());
	}, [searchFilter]);

	const areTwoColumnsNeeded =
		[
			isSearchEnabled,
			isJobsEnabled,
			isWCAGLevelsEnabled,
			isTagsEnabled,
		].filter((value) => !!value).length > 2;

	return (
		<search aria-label="Filters" className="mb-4 grid gap-4">
			<div
				className={`grid gap-4 ${areTwoColumnsNeeded ? 'md:grid-cols-2' : ''}`}
			>
				{(isSearchEnabled || isJobsEnabled) && (
					<div className="flex flex-col gap-4">
						<form
							className="shadow-border-bottom-sm relative w-full max-w-md text-white after:rounded-full"
							onSubmit={(e) => e.preventDefault()}
						>
							<input
								aria-label="Zoeken (lijst wordt live bijgewerkt)"
								type="search"
								placeholder="Zoeken"
								className="block w-full rounded-full bg-primary-500 py-2 pe-6 ps-12 text-lg font-bold text-white placeholder:text-white dark:bg-primary-950"
								value={searchFilter}
								onChange={(e) =>
									setSearchFilter(e.target.value)
								}
							/>
							<Icon
								path={mdiMagnify}
								size={1}
								className="pointer-events-none absolute left-4 top-1/2 -translate-y-1/2"
								aria-hidden
							/>
						</form>
						<Filter
							className="grow"
							urlName="professions"
							displayName="Beroepen"
							items={Jobs.map((job) => ({
								id: job.id,
								title: job.title,
								colors: {
									bg: job.colors.bg.light,
									text: job.colors.text.dark,
								},
							}))}
						/>
					</div>
				)}
				{(isWCAGLevelsEnabled || isTagsEnabled) && (
					<div
						className={`grid gap-4 ${
							!isSearchEnabled && !isJobsEnabled
								? 'md:grid-cols-2'
								: ''
						}`}
					>
						<Filter
							urlName="levels"
							displayName="Niveau's"
							items={[
								{
									id: 'a-aa',
									title: 'Niveau A en AA',
									colors: {
										bg: 'bg-primary-200',
										text: 'text-primary-950',
									},
								},
								{
									id: 'aaa',
									title: 'Niveau AAA (optioneel)',
									colors: {
										bg: 'bg-quaternary-200',
										text: 'text-green-950',
									},
								},
							]}
						/>
						<Filter
							urlName="tags"
							displayName="Redactionele content"
							messages={{
								whileFiltering:
									'Combineer waar nodig. Heb je bewegend beeld met audio? Of stilstaand beeld met tekst? Selecteer ze dan allebei.',
							}}
							items={Tags.map((tag) => ({
								id: tag.id,
								title: tag.title,
								colors: {
									bg: tag.colors.bg.light,
									text: tag.colors.text.dark,
								},
							}))}
						/>
					</div>
				)}
			</div>
		</search>
	);
};

export default GuidelinesFilters;
